var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-4" },
    [
      _vm.$vuetify.breakpoint.forPC
        ? _c(
            "page-section",
            { staticClass: "pa-0 ma-0", staticStyle: { height: "50px" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "grey lighten-4 mx-auto",
                  staticStyle: { display: "block" },
                  attrs: {
                    fab: "",
                    depressed: "",
                    width: "100",
                    height: "100",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$vuetify.goTo(0)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/web-figures/back-to-top-button.png"),
                    },
                  }),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "page-section",
        { staticClass: "grey lighten-4" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: _vm.$vuetify.breakpoint.forPC ? 6 : 12 } },
                [
                  _c(
                    "div",
                    { attrs: { id: "tutti" } },
                    [
                      _c("img", {
                        attrs: {
                          id: "tutti-logo",
                          src: require("@/assets/web-figures/logo.png"),
                        },
                      }),
                      _c("v-divider", { staticClass: "divider mb-6" }),
                      _c(
                        "div",
                        { staticClass: "d-flex", attrs: { id: "tutti-menu" } },
                        [
                          _c("div", [
                            _c("p", [
                              _c(
                                "a",
                                { staticClass: "link", attrs: { href: "/" } },
                                [_vm._v("トップ")]
                              ),
                            ]),
                            _c("p", [
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: { href: "/features" },
                                },
                                [_vm._v("機能")]
                              ),
                            ]),
                            _c("p", [
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: { href: "/prices" },
                                },
                                [_vm._v("料金・導入フロー")]
                              ),
                            ]),
                            _c("p", [
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: {
                                    href: "https://www.iflab.co.jp/",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v("会社概要"),
                                  _c(
                                    "v-icon",
                                    { attrs: { small: "", right: "" } },
                                    [_vm._v("mdi-open-in-new")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-col",
                { attrs: { cols: _vm.$vuetify.breakpoint.forPC ? 6 : 12 } },
                [
                  _c(
                    "div",
                    { attrs: { id: "iflab" } },
                    [
                      _c("img", {
                        attrs: {
                          id: "iflab-logo",
                          src: require("@/assets/web-figures/iflab-logo.png"),
                        },
                      }),
                      _c("v-divider", { staticClass: "divider mb-6" }),
                      _c(
                        "div",
                        { staticClass: "d-flex", attrs: { id: "iflab-menu" } },
                        [
                          _c("div", [
                            _c("p", [
                              _vm._v(
                                "株式会社知能フレームワーク研究所（ifLab）"
                              ),
                            ]),
                            _c("p", [_vm._v("〒143-0015")]),
                            _c("p", [_vm._v("東京都大田区大森西2-22-19")]),
                            _c("p", [_vm._v("info@iflab.co.jp")]),
                            _c("p", [_vm._v("080-1987-9784")]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c("div", { attrs: { id: "copyright" } }, [
            _vm._v(
              " © " +
                _vm._s(_vm.currentYear) +
                " Intelligent Framework Lab Inc. "
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }